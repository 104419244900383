import _ from "lodash";
import usePostLogin from "./usePostLogin";
import { TokenInterface } from "../interfaces/TokenInterface";
import { useEffect } from "react";
import useStorage from "../hooks/useStorage";
import useAuthenticationStore from "../state/useAuthenticationStore";

interface UseAuthenticationInterface {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<TokenInterface | null>;
  isLoggedIn: boolean;
  token: TokenInterface | null;
  logout: () => void;
}

const useAuthentication = (): UseAuthenticationInterface => {
  const token = useAuthenticationStore((state) => state.token);
  const set = useAuthenticationStore((state) => state.set);
  const { getItem, setItem, removeItem } = useStorage();

  const { login: apiLogin } = usePostLogin();

  useEffect(() => {
    const storedToken = getItem("token");
    if (storedToken === null) {
      return;
    }
    const parsedToken: TokenInterface = JSON.parse(storedToken);
    if (_.isEqual(token, parsedToken)) {
      return;
    }
    set(parsedToken);
  }, [token, getItem]);

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<TokenInterface | null> => {
    const apiToken = await apiLogin({ email, password });

    if (apiToken === null) {
      return null;
    }
    setItem("token", JSON.stringify(apiToken));
    set(apiToken);

    return apiToken;
  };

  const logout = (): void => {
    set(null);
    removeItem("token");
  };

  return { login, isLoggedIn: token !== null, token, logout };
};

export default useAuthentication;
