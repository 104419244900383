import React, { ReactElement } from "react";
import { InputFieldProps } from "../../interfaces/InputFieldProps";
import StyledField from "../styles/StyledField";
import StyledInputField from "../styles/StyledInputField";
import StyledLabel from "../styles/StyledLabel";
import styled from "styled-components";
import CopyIcon from "../../icons/CopyIcon";
import copyToClipboard from "../../helpers/copyToClipboard";

const StyledDiv = styled.div`
  display: flex;
`;
const StyledCopyButton = styled.a`
  cursor: pointer;
`;

const InputField = ({
  label,
  type,
  name,
  handleChange,
  handleBlur,
  value,
  readOnly,
  isCopyActive,
}: InputFieldProps): ReactElement => {
  if (typeof value !== "string" && typeof value !== "number") {
    throw new Error("Invalid input field data");
  }

  const handleCopyToClipboard = (): void => {
    copyToClipboard(`${value}`);
  };

  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      {isCopyActive !== undefined && isCopyActive && (
        <StyledDiv>
          <StyledInputField
            type={type}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            readOnly={readOnly}
          />
          <StyledCopyButton onClick={handleCopyToClipboard}>
            <CopyIcon />
          </StyledCopyButton>
        </StyledDiv>
      )}
      {(isCopyActive === undefined || !isCopyActive) && (
        <>
          <StyledInputField
            type={type}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            readOnly={readOnly}
          />
        </>
      )}
    </StyledField>
  );
};

export default InputField;
