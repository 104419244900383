import React, { MouseEventHandler, ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface ClickableProps {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}

const StyledA = styled.a`
  cursor: pointer;
`;

const Clickable = ({ children, onClick }: ClickableProps): ReactElement => {
  return <StyledA onClick={onClick}>{children}</StyledA>;
};

export default Clickable;
