import useApi from "./useApi";

interface UsePostFileInterface {
  request: () => Promise<any>;
}

const useGetSignature = (): UsePostFileInterface => {
  const { get } = useApi();

  const request = async (): Promise<any> => {
    const { status, data } = await get(`/signature/file`, {}, true);

    if (status === 200) {
      return data;
    }

    return null;
  };

  return { request };
};

export default useGetSignature;
