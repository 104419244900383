import styled from "styled-components";

const StyledInputField = styled.input`
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
`;

export default StyledInputField;
