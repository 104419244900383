import React, { ReactElement } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "100%",
  },
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize: 12,
  },
});

interface PatientPdfProps {
  firstLine: string;
  url: string;
  authKey: string;
}
const PatientPdf = ({
  firstLine,
  url,
  authKey,
}: PatientPdfProps): ReactElement => {
  return (
    <PDFViewer style={styles.pdfViewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              {`${firstLine}
                
Ihr Rezept wurde auf eine gesicherte URL übertragen. 

Unter dieser URL kann der/die Apotheker/in das Rezept einmalig einsehen:

`}
              <Link src={url}>{`${url}`}</Link>
              {`
              
 Dazu wird die folgende PIN benötigt: ${authKey}

Bitte leiten Sie beides an die Apotheke weiter. 

Mit freundlichen Grüßen
D. Hercher`}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PatientPdf;
