import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { Layout, MainColumn, StyledContent } from "../components";

const LoggedOutApp = (): ReactElement => {
  return (
    <Layout>
      <MainColumn>
        <StyledContent>
          <Outlet />
        </StyledContent>
      </MainColumn>
    </Layout>
  );
};

export default LoggedOutApp;
