import React, { ReactElement } from "react";
import { ControlBar, StyledIconButton, StyledRow } from "../components";
import { SidebarIcon } from "../icons";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "../hooks/useBreadcrumbs";
import styled from "styled-components";

interface BreadcrumbsProps {
  handleToggleClick: () => void;
  isActive: boolean;
}

const StyledDullBreadcrumb = styled.span`
  color: ${({ theme }) => theme.colors["black/40"]};
`;

const StyledSeparator = styled.span`
  display: inline-block;
  margin: 0 2rem;
`;

const Breadcrumbs = ({
  isActive,
  handleToggleClick,
}: BreadcrumbsProps): ReactElement => {
  const { t } = useTranslation();
  const { breadcrumbs } = useBreadcrumbs();

  const getBreadcrumbText = (key: string): string => {
    return key === "" ? t("NAVIGATION.overview") : t(`NAVIGATION.${key}`);
  };

  return (
    <ControlBar>
      <StyledRow>
        <StyledIconButton isActive={isActive} onClick={handleToggleClick}>
          <SidebarIcon />
        </StyledIconButton>
        {breadcrumbs.map((breadcrumb, iterator) => (
          <React.Fragment key={breadcrumb}>
            {iterator + 1 < breadcrumbs.length ? (
              <StyledDullBreadcrumb>
                {getBreadcrumbText(breadcrumb)}
                {breadcrumbs.length > 1 &&
                  iterator + 1 !== breadcrumbs.length && (
                    <StyledSeparator>/</StyledSeparator>
                  )}
              </StyledDullBreadcrumb>
            ) : (
              <>{getBreadcrumbText(breadcrumb)}</>
            )}
          </React.Fragment>
        ))}
      </StyledRow>
    </ControlBar>
  );
};

export default Breadcrumbs;
