import React, { ReactElement, ReactNode } from "react";
import { Formik } from "formik";
import InputField from "./InputField";
import RelationField from "./RelationField";
import { RelationInterface } from "../../interfaces/RelationInterface";
import { FormFieldType } from "../../interfaces/FormFieldType";
import { InputFieldProps } from "../../interfaces/InputFieldProps";

export type RelationType = RelationInterface[];

interface FormProps {
  fields: FormFieldInterface[];
  initialValues: Record<string, string | RelationType>;
  handleSubmit: (values: Record<string, any>) => void;
  children: ReactNode;
  populate?: Record<string, RelationType>;
}

interface FormFieldInterface {
  id: string;
  type: FormFieldType;
  label: string;
}

const RenderFormField = ({
  type,
  handleChange,
  handleBlur,
  value,
  name,
  label,
  handleRelationChange,
  populate,
}: InputFieldProps): ReactElement => {
  const handleRelationFieldChange = ({ value }: { value: any }): void => {
    if (handleRelationChange === undefined) {
      return;
    }
    if (populate === undefined || populate[name] === undefined) {
      handleRelationChange([{ id: "new", label: value }]);
      return;
    }
    const found = populate[name].find((pop) => {
      return pop.id === value;
    });

    if (found === undefined) {
      handleRelationChange([{ id: "new", label: value }]);
      return;
    }
    handleRelationChange([{ id: value, label: found.label }]);
  };

  switch (type) {
    case "email":
    case "phone":
    case "text":
    case "password":
      return (
        <InputField
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value === null ? "" : value}
          name={name}
          type={type}
          label={label}
        />
      );

    case "relation":
      return (
        <RelationField
          handleChange={({ target: { value } }) => {
            handleRelationFieldChange({ value });
          }}
          populate={populate}
          handleBlur={handleBlur}
          value={value}
          name={name}
          type={type}
          label={label}
        />
      );
  }

  return <></>;
};

const Form = ({
  fields,
  initialValues,
  handleSubmit,
  children,
  populate,
}: FormProps): ReactElement => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {fields.map(({ id, type, label }) => (
              <React.Fragment key={id}>
                <RenderFormField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values[id]}
                  name={id}
                  type={type}
                  label={label}
                  handleRelationChange={(value) => (values[id] = value)}
                  populate={populate}
                />
              </React.Fragment>
            ))}
            {children}
          </form>
        )}
      </Formik>
    </>
  );
};
export default Form;
