import { create } from "zustand";
import { TokenInterface } from "../interfaces/TokenInterface";

interface AuthenticationState {
  token: TokenInterface | null;
  set: (token: TokenInterface | null) => void;
}
const useAuthenticationStore = create<AuthenticationState>((set) => ({
  token: null,
  set: (token) => set(() => ({ token })),
}));

export default useAuthenticationStore;
