import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

const StyledTag = styled.span`
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.blue};
  padding: 0 1rem;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
`;

const Tag = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <>
      <StyledTag>{children}</StyledTag>
    </>
  );
};

export default Tag;
