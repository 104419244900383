import client from "./client";
import getApiUrl from "../helpers/getApiUrl";
import { AxiosResponse } from "axios";
import useAuthentication from "./useAuthentication";
import { useNavigate } from "react-router-dom";

interface UseApiInterface {
  get: <R = AxiosResponse>(
    route: string,
    additionalHeaders?: Record<string, string>,
    isStream?: boolean
  ) => Promise<R>;
  post: <R = AxiosResponse>(
    route: string,
    data: any,
    additionalHeaders?: Record<string, string>
  ) => Promise<R>;
  put: <R = AxiosResponse>(route: string, data: any) => Promise<R>;
  deleteRequest: <R = AxiosResponse>(route: string) => Promise<R>;
}

const useApi = (): UseApiInterface => {
  const { token, logout } = useAuthentication();
  const navigate = useNavigate();

  const headers =
    token === null
      ? null
      : {
          Authorization: `Bearer ${token.token}`,
        };

  client.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      if (error.response.status === 401) {
        logout();
        navigate("/login");
      }
      return await Promise.reject(error);
    }
  );

  const get = async <R = AxiosResponse>(
    route: string,
    additionalHeaders?: Record<string, string>,
    isBlob?: boolean
  ): Promise<R> => {
    const responseType = isBlob !== undefined && isBlob ? "blob" : undefined;
    return await client
      .get(`${getApiUrl()}${route}`, {
        headers: { ...headers, ...additionalHeaders },
        responseType,
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          navigate("/login");
          return;
        }
        return error;
      });
  };
  const post = async <R = AxiosResponse>(
    route: string,
    data: any,
    additionalHeaders?: Record<string, string>
  ): Promise<R> => {
    return await client.post(`${getApiUrl()}${route}`, data, {
      headers: { ...headers, ...additionalHeaders },
    });
  };
  const put = async <R = AxiosResponse>(
    route: string,
    data: any
  ): Promise<R> => {
    return await client.put(`${getApiUrl()}${route}`, data, {
      headers: { ...headers },
    });
  };
  const deleteRequest = async <R = AxiosResponse>(
    route: string
  ): Promise<R> => {
    return await client.delete(`${getApiUrl()}${route}`, {
      headers: { ...headers },
    });
  };

  return { get, post, put, deleteRequest };
};

export default useApi;
