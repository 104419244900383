import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface HeadlineProps {
  children: ReactNode;
  pl?: string;
}

const StyledHeadline = styled.h2<{ pl: string }>`
  font-weight: 600;
  font-size: 1.75rem;
  margin: 0 0 2rem 0;
  padding-left: ${({ pl }) => pl};
`;

const Headline = ({ children, pl = "0" }: HeadlineProps): ReactElement => {
  return <StyledHeadline pl={pl}>{children}</StyledHeadline>;
};

export default Headline;
