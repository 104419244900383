import styled, { createGlobalStyle } from "styled-components";
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  Row,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import React, { ReactElement, useEffect, useState } from "react";
import { LoadingTableSkeleton, StyledTh } from "./index";
import StyledTd from "./styles/StyledTd";
import StyledTr from "./styles/StyledTr";
import StyledEditSpan from "./Table/StyledEditSpan";
import { ShowMoreOptionInterface } from "../interfaces/ShowMoreOptionInterface";
import ShowMore from "./Table/ShowMore";

const TableStyle = createGlobalStyle`
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 1.5rem;
    }
  `;

interface TableProps<TData extends RowData> {
  data: TData[];
  columns: Array<ColumnDef<TData, any>>;
  isLoading: boolean;
  isShowMore?: boolean;
  options?: ShowMoreOptionInterface[];
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  filter?: ColumnFiltersState;
}

const StyledMoreTd = styled(StyledTd)`
  text-align: right;
  width: 1px;
  padding: 0;
`;

const StyledEditTr = styled(StyledTr)`
  &:hover ${StyledEditSpan} {
    visibility: visible;
    opacity: 1;
  }
`;

interface IdAware {
  id?: string;
}

interface FilterFunctionInterface {
  filter: (row: Row<unknown>) => boolean;
}

const fuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  value: FilterFunctionInterface
): boolean => {
  return value.filter(row);
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
}

const Table = <TData extends IdAware>({
  data,
  columns,
  isLoading,
  options,
  renderSubComponent,
  getRowCanExpand,
  filter,
}: TableProps<TData>): ReactElement => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    filter ?? []
  );

  useEffect(() => {
    setColumnFilters(filter ?? []);
  }, [filter]);

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
  });

  return (
    <>
      <TableStyle />
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledTh key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </StyledTh>
              ))}
              {options !== undefined && <StyledTh />}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading && <LoadingTableSkeleton table={table} />}
          {!isLoading && (
            <>
              {table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <StyledEditTr>
                    {row.getVisibleCells().map((cell) => (
                      <StyledTd key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </StyledTd>
                    ))}
                    {options !== undefined && row.original.id !== undefined && (
                      <StyledMoreTd>
                        <ShowMore
                          options={options}
                          id={row.original.id}
                          handleExpand={row.getToggleExpandedHandler()}
                        />
                      </StyledMoreTd>
                    )}
                  </StyledEditTr>
                  {row.getIsExpanded() && renderSubComponent !== undefined && (
                    <StyledTr hoverEffect={false}>
                      <td colSpan={row.getVisibleCells().length + 1}>
                        {renderSubComponent({ row })}
                      </td>
                    </StyledTr>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
