import React, { ReactElement } from "react";
import {
  SidebarList,
  SidebarListElement,
  SidebarSubListElement,
  SidebarToggleList,
} from "../components";
import {
  NavigationItemInterface,
  NavigationType,
} from "../interfaces/NavigationItemInterface";

interface NavigationProps {
  title: string;
  navigation: NavigationType;
  onNavigationChange: (id: string) => void;
  currentRoute: string;
}

interface NavigationItemProps {
  label: string;
  id: string;
  children?: NavigationItemInterface[];
  onClick: (id: string) => void;
  isActive: boolean;
}

const NavigationItem = ({
  label,
  children,
  id,
  onClick,
  isActive,
}: NavigationItemProps): ReactElement => {
  if (children === undefined) {
    return (
      <SidebarListElement onClick={() => onClick(id)} isActive={isActive}>
        {label}
      </SidebarListElement>
    );
  }

  return (
    <SidebarToggleList title={label}>
      {children.map((child) => (
        <SidebarSubListElement
          key={JSON.stringify([child.label, child.route])}
          onClick={() => onClick(child.route)}
        >
          {child.label}
        </SidebarSubListElement>
      ))}
    </SidebarToggleList>
  );
};

const Navigation = ({
  title,
  navigation,
  onNavigationChange,
  currentRoute,
}: NavigationProps): ReactElement => {
  return (
    <SidebarList title={title}>
      {navigation.map(({ label, children, route }) => (
        <NavigationItem
          label={label}
          key={JSON.stringify([label, route])}
          id={route}
          onClick={onNavigationChange}
          isActive={currentRoute === route}
        >
          {children}
        </NavigationItem>
      ))}
    </SidebarList>
  );
};

export default Navigation;
