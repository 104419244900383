import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface RowProps {
  children: ReactNode;
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1.5rem 3rem;
`;

const Row = ({ children }: RowProps): ReactElement => {
  return <StyledRow>{children}</StyledRow>;
};

export default Row;
