interface UseStorageInterface {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

const useStorage = (): UseStorageInterface => {
  const prefix = process.env.REACT_APP_STORAGE_PREFIX ?? "";

  const getKey = (key: string): string => `${prefix}_${key}`;

  const getItem = (key: string): string | null => {
    return localStorage.getItem(getKey(key));
  };
  const setItem = (key: string, value: string): void => {
    localStorage.setItem(getKey(key), value);
  };

  const removeItem = (key: string): void => {
    localStorage.removeItem(getKey(key));
  };

  return { getItem, setItem, removeItem };
};

export default useStorage;
