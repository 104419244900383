import { motion } from "framer-motion";
import RefreshIcon from "../icons/RefreshIcon";
import React, { ReactElement } from "react";

const LoadingIcon = motion(RefreshIcon);

interface LoadingProps {
  size?: number;
  fill?: string;
}

const Loading = ({ size, fill }: LoadingProps): ReactElement => {
  return (
    <LoadingIcon
      fill={fill}
      size={size}
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 1,
        ease: "linear",
        repeat: Infinity,
      }}
    />
  );
};

export default Loading;
