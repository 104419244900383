import styled from "styled-components";

const Link = styled.a<{
  variant?: "default" | "success";
  block?: boolean;
}>`
  border: 0;
  border-radius: 1rem;
  background: ${({ theme, variant }) =>
    variant === "success" ? theme.colors.success : theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem 2rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  ${({ block }) =>
    block !== undefined && block ? "display: block; width: 100%;" : ""}

  &:hover {
    background: ${({ theme, variant }) =>
      variant === "success" ? theme.colors.success : theme.colors.darkgray};
  }
`;

export default Link;
