import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  ColorType,
  Headline,
  InputField,
  Space,
} from "../components";
import { useTranslation } from "react-i18next";
import usePostRecipe from "../api/usePostRecipe";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import Loading from "../components/Loading";
import { UploadPrescriptionInterface } from "../interfaces/UploadPrescriptionInterface";
import SelectField from "../components/Form/SelectField";
import { Formik } from "formik";
import PatientPdf from "../components/PdfViewer/PatientPdf";
import styled from "styled-components";
import Link from "../components/Link";
import getPdfText from "../helpers/getPdfText";
import copyToClipboard from "../helpers/copyToClipboard";

type GenderType = "ms" | "mr" | "diverse";

const StyledPdfWrapper = styled.div`
  height: 100vh;
`;

interface AnimatedCardProps {
  children: ReactNode;
  color?: ColorType;
  handleAnimationComplete: () => void;
}

const AnimatedCard = ({
  children,
  color,
  handleAnimationComplete,
}: AnimatedCardProps): ReactElement => {
  return (
    <Card
      transition={{ delay: 0.5, duration: 0.5, type: "tween" }}
      initial={{ backgroundColor: "#E3F5FF" }}
      animate={
        color === "purple"
          ? {
              backgroundColor: [
                "#E3F5FF",
                "#28a745",
                "#E3F5FF",
                "#28a745",
                "#E3F5FF",
              ],
            }
          : {}
      }
      onAnimationComplete={handleAnimationComplete}
    >
      {children}
    </Card>
  );
};

const Upload = (): ReactElement => {
  const { t } = useTranslation();
  const { create, createAndTransform } = usePostRecipe();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [chosenFile, setChosenFile] = useState<File | null>(null);
  const [chosenFileWithSignature, setChosenFileWithSignature] =
    useState<File | null>(null);
  const [prescriptionInformation, setPrescriptionInformation] =
    useState<UploadPrescriptionInterface | null>(null);
  const [pdfData, setPdfData] = useState<{
    firstLine: string;
    key: string;
    url: string;
  } | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [color, setColor] = useState<"blue" | "purple">("blue");

  useEffect(() => {
    if (chosenFile === null) {
      return;
    }
    setDownloadUrl(null);
    setStatus("loading");
    void create(chosenFile).then((prescription) => {
      setStatus("success");
      setPrescriptionInformation(prescription);
    });
  }, [chosenFile]);

  useEffect(() => {
    if (chosenFileWithSignature === null) {
      return;
    }
    setDownloadUrl(null);
    setStatus("loading");
    void createAndTransform(chosenFileWithSignature).then((prescription) => {
      if (prescription?.bytes !== undefined) {
        const array = new Uint8Array(Object.values(prescription.bytes));
        setDownloadUrl(
          URL.createObjectURL(
            new Blob([array.buffer], { type: "application/pdf" })
          )
        );
      }
      setStatus("success");
      setPrescriptionInformation(prescription);
    });
  }, [chosenFileWithSignature]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.currentTarget.files;

    if (files === null) {
      return;
    }

    const file = files.item(0);
    setChosenFile(file);
  };

  const handleFileWithSignatureChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const files = event.currentTarget.files;

    if (files === null) {
      return;
    }

    const file = files.item(0);
    setChosenFileWithSignature(file);
  };

  const url = process.env.REACT_APP_APP_URL ?? "";

  return (
    <>
      <Headline pl="1rem">{t("PRESCRIPTION.upload")}</Headline>
      <Card>
        <input type="file" onChange={handleFileChange} />
      </Card>
      <Space size={4} />
      <Headline pl="1rem">{t("PRESCRIPTION.upload-with-signature")}</Headline>
      <Card>
        <input type="file" onChange={handleFileWithSignatureChange} />
      </Card>
      <Space size={2} />
      {status === "loading" && (
        <Card color="purple">
          <Loading />
        </Card>
      )}
      {status === "success" && (
        <>
          {prescriptionInformation !== null && (
            <>
              {downloadUrl !== null && (
                <Card color="blue">
                  <Link
                    href={downloadUrl}
                    download={`${prescriptionInformation.token}.pdf`}
                  >
                    {t("PRESCRIPTION.download-copy")}
                  </Link>
                </Card>
              )}
              <Space size={2} />
              <Card color="blue">
                <InputField
                  label={t("PRESCRIPTION.url")}
                  type="text"
                  name="address"
                  handleChange={() => {}}
                  value={`${url}/${prescriptionInformation.token}`}
                  readOnly
                  isCopyActive
                />
                <InputField
                  label={t("PRESCRIPTION.key")}
                  type="text"
                  name="address"
                  handleChange={() => {}}
                  value={prescriptionInformation.key}
                  readOnly
                  isCopyActive
                />
                <InputField
                  label={t("PRESCRIPTION.patient-text")}
                  type="text"
                  name="address"
                  handleChange={() => {}}
                  value={getPdfText({
                    firstLine: pdfData?.firstLine ?? "Frau/Herr ...,",
                    url: `${url}/${prescriptionInformation.token}`,
                    key: prescriptionInformation.key,
                  })}
                  readOnly
                  isCopyActive
                />
              </Card>
              <Space size={2} />
              <AnimatedCard
                color={color}
                handleAnimationComplete={() => setColor("blue")}
              >
                PDF generieren:
                <Space size={2} />
                <Formik<{
                  firstName: string;
                  lastName: string;
                  gender: GenderType;
                }>
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    gender: "mr",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setPdfData(null);

                    const firstLine =
                      values.gender === "diverse"
                        ? `${t(`GENERATE_PDF.gender-selection.diverse-pdf`)} ${
                            values.firstName
                          } ${values.lastName},`
                        : `${t(
                            `GENERATE_PDF.gender-selection.${values.gender}`
                          )} ${values.lastName},`;

                    const data: {
                      firstLine: string;
                      key: string;
                      url: string;
                    } = {
                      firstLine,
                      key: prescriptionInformation.key,
                      url: `${url}/${prescriptionInformation.token}`,
                    };

                    setPdfData(data);
                    copyToClipboard(getPdfText(data));
                    setColor("purple");
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <SelectField
                          label={t("GENERATE_PDF.gender")}
                          handleBlur={handleBlur}
                          name="gender"
                          handleChange={handleChange}
                          value={values.gender}
                        >
                          <option value="mr">
                            {t("GENERATE_PDF.gender-selection.mr")}
                          </option>
                          <option value="ms">
                            {t("GENERATE_PDF.gender-selection.ms")}
                          </option>
                          <option value="diverse">
                            {t("GENERATE_PDF.gender-selection.diverse")}
                          </option>
                        </SelectField>
                        {values.gender === "diverse" && (
                          <InputField
                            label={t("GENERATE_PDF.first-name")}
                            handleBlur={handleBlur}
                            type="text"
                            name="firstName"
                            handleChange={handleChange}
                            value={values.firstName}
                          />
                        )}
                        <InputField
                          handleBlur={handleBlur}
                          label={t("GENERATE_PDF.last-name")}
                          type="text"
                          name="lastName"
                          handleChange={handleChange}
                          value={values.lastName}
                        />
                        <Button type="submit">
                          {t("GENERATE_PDF.generate")}
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </AnimatedCard>
              {pdfData !== null && (
                <>
                  <Space size={2} />
                  <StyledPdfWrapper>
                    <PatientPdf
                      firstLine={pdfData.firstLine}
                      url={pdfData.url}
                      authKey={pdfData.key}
                    />
                  </StyledPdfWrapper>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Upload;
