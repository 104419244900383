import useApi from "./useApi";
import { UploadPrescriptionInterface } from "../interfaces/UploadPrescriptionInterface";

interface UsePostFileInterface {
  create: (file: File) => Promise<UploadPrescriptionInterface | null>;
  createAndTransform: (
    file: File
  ) => Promise<UploadPrescriptionInterface | null>;
}

const usePostRecipe = (): UsePostFileInterface => {
  const { post } = useApi();

  const create = async (
    file: File
  ): Promise<UploadPrescriptionInterface | null> => {
    const formData = new FormData();
    formData.append("file", file);

    const { status, data } = await post("", formData, {
      "Content-Type": "multipart/form-data",
    });

    if (status === 200) {
      return data;
    }

    return null;
  };

  const createAndTransform = async (
    file: File
  ): Promise<UploadPrescriptionInterface | null> => {
    const formData = new FormData();
    formData.append("file", file);

    const { status, data } = await post("/recipe", formData, {
      "Content-Type": "multipart/form-data",
    });

    if (status === 200) {
      return data;
    }

    return null;
  };

  return { create, createAndTransform };
};

export default usePostRecipe;
