import React, { ReactElement } from "react";
import overlay from "../../assets/overlay-top.png";
import styled from "styled-components";

const StyledOverlayImage = styled.img`
  width: 173px;
`;

const StyledOverlayDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Overlay = (): ReactElement => {
  return (
    <StyledOverlayDiv>
      <StyledOverlayImage src={overlay} alt="Overlay Image" />
    </StyledOverlayDiv>
  );
};

export default Overlay;
