import React, { ReactElement, useState } from "react";
import { ContentRenderer, Popover } from "react-tiny-popover";
import { MoreIcon } from "../../icons";
import styled from "styled-components";
import StyledEditSpan from "./StyledEditSpan";
import { ShowMoreOptionInterface } from "../../interfaces/ShowMoreOptionInterface";

const StyledPopover = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: ${(props) => props.theme.shadows.default}
    ${(props) => props.theme.colors.gray};
`;

const StyledPopoverUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 20rem;
`;

const StyledPopoverLi = styled.li`
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.light};
  }
`;

const OptionsPopover = ({
  isPopoverOpen,
  handleMoreClick,
  closePopover,
  children,
}: {
  isPopoverOpen: boolean;
  handleMoreClick: () => void;
  closePopover: () => void;
  children: JSX.Element | ContentRenderer;
}): ReactElement => {
  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom", "right", "top", "left"]}
        align="end"
        onClickOutside={closePopover}
        content={children}
      >
        <StyledEditSpan onClick={handleMoreClick}>
          <MoreIcon />
        </StyledEditSpan>
      </Popover>
    </>
  );
};

const ShowMore = ({
  options,
  id,
  handleExpand,
}: {
  options: ShowMoreOptionInterface[];
  id: string;
  handleExpand: () => void;
}): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleMoreClick = (): void => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <OptionsPopover
      isPopoverOpen={isPopoverOpen}
      handleMoreClick={handleMoreClick}
      closePopover={() => setIsPopoverOpen(false)}
    >
      <StyledPopover>
        <StyledPopoverUl>
          {options.map((option) => (
            <StyledPopoverLi
              key={option.label}
              onClick={() => {
                setIsPopoverOpen(false);
                if (option.onClick === undefined) {
                  handleExpand();
                  return;
                }
                void option.onClick(id);
              }}
            >
              {option.label}
            </StyledPopoverLi>
          ))}
        </StyledPopoverUl>
      </StyledPopover>
    </OptionsPopover>
  );
};

export default ShowMore;
