import React, { ReactElement } from "react";
import "modern-normalize/modern-normalize.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme/theme";
import LoggedInApp from "./modules/LoggedInApp";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as de from "./languages/de.json";
import LoggedOutApp from "./modules/LoggedOutApp";
import Upload from "./modules/Upload";
import LoginForm from "./modules/LoginForm";
import RequestPrescription from "./modules/RequestPrescription";
import Signature from "./modules/Signature";

void i18n
  .use(initReactI18next)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      de: {
        translation: {
          ...de,
        },
      },
    },
    lng: "de",
    fallbackLng: "de",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .catch();

function App(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoggedOutApp />}>
            <Route index element={<LoginForm />} />
          </Route>
          <Route path="/" element={<LoggedOutApp />}>
            <Route index element={<RequestPrescription />} />
            <Route path=":id" element={<RequestPrescription />} />
          </Route>
          <Route path="/upload" element={<LoggedInApp />}>
            <Route index element={<Upload />} />
            <Route path="*" element={<>Under construction.</>} />
          </Route>
          <Route path="/signature" element={<LoggedInApp />}>
            <Route index element={<Signature />} />
            <Route path="*" element={<>Under construction.</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
