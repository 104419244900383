import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface CenterProps {
  children: ReactNode;
}

const StyledCenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Center = ({ children }: CenterProps): ReactElement => {
  return <StyledCenterDiv>{children}</StyledCenterDiv>;
};

export default Center;
