import React, { ReactElement } from "react";

interface RefreshIconProps {
  size?: number;
  fill?: string;
}
const RefreshIcon = React.forwardRef<SVGSVGElement, RefreshIconProps>(
  ({ size, fill }, ref): ReactElement => (
    <svg
      ref={ref}
      width={size ?? "32"}
      height={size ?? "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.025 6.46251V12.4625C29.025 12.7277 28.9196 12.9821 28.7321 13.1696C28.5445 13.3571 28.2902 13.4625 28.025 13.4625H22.025C21.8282 13.4622 21.6358 13.4038 21.4721 13.2947C21.3083 13.1856 21.1804 13.0305 21.1043 12.849C21.0283 12.6676 21.0074 12.4677 21.0445 12.2744C21.0815 12.0811 21.1747 11.903 21.3125 11.7625L23.6125 9.4625L23.075 8.92501C21.676 7.52759 19.8941 6.57629 17.9546 6.19134C16.0151 5.80639 14.005 6.00507 12.1784 6.76227C10.3518 7.51948 8.79068 8.80121 7.69235 10.4455C6.59403 12.0897 6.00782 14.0227 6.00782 16C6.00782 17.9773 6.59403 19.9103 7.69235 21.5546C8.79068 23.1988 10.3518 24.4805 12.1784 25.2377C14.005 25.9949 16.0151 26.1936 17.9546 25.8087C19.8941 25.4237 21.676 24.4724 23.075 23.075C23.1669 22.9809 23.2768 22.9061 23.3981 22.8551C23.5194 22.804 23.6496 22.7777 23.7812 22.7777C23.9128 22.7777 24.0431 22.804 24.1643 22.8551C24.2856 22.9061 24.3955 22.9809 24.4875 23.075C24.6745 23.2625 24.7795 23.5165 24.7795 23.7813C24.7795 24.0461 24.6745 24.3 24.4875 24.4875C22.809 26.1651 20.6708 27.3074 18.3432 27.7699C16.0156 28.2324 13.6031 27.9944 11.4107 27.086C9.2184 26.1775 7.34462 24.6395 6.02632 22.6662C4.70802 20.6929 4.00439 18.3731 4.00439 16C4.00439 13.6269 4.70802 11.3071 6.02632 9.33382C7.34462 7.36056 9.2184 5.82249 11.4107 4.91405C13.6031 4.00562 16.0156 3.76761 18.3432 4.23012C20.6708 4.69264 22.809 5.8349 24.4875 7.51251L25.025 8.05001L27.3125 5.76251C27.4512 5.62172 27.6285 5.52522 27.8221 5.48517C28.0157 5.44512 28.2167 5.46333 28.4 5.53751C28.5824 5.61479 28.7385 5.74329 28.8494 5.90745C28.9604 6.07162 29.0213 6.26441 29.025 6.46251Z"
        fill={fill ?? "#1C1C1C"}
      />
    </svg>
  )
);

RefreshIcon.displayName = "RefreshIcon";

export default RefreshIcon;
