import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledTableControlBar = styled.div`
  background-color: ${(props) => props.theme.colors.light};
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
`;

interface TableControlBarProps {
  controls: Array<{
    action: string;
    element: () => ReactElement;
  }>;
  handleAction: (id: string) => void;
}

const StyledControlButton = styled.button`
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
`;

const TableControlBar = ({
  controls,
  handleAction,
}: TableControlBarProps): ReactElement => {
  return (
    <StyledTableControlBar>
      {controls.map((control) => {
        return (
          <StyledControlButton
            onClick={() => handleAction(control.action)}
            key={control.action}
          >
            <control.element />
          </StyledControlButton>
        );
      })}
    </StyledTableControlBar>
  );
};
export default TableControlBar;
