import { useTranslation } from "react-i18next";
import { NavigationItemInterface } from "../interfaces/NavigationItemInterface";

interface UseNavigationInterface {
  navigation: Array<{ label: string; items: NavigationItemInterface[] }>;
}
const useNavigation = (): UseNavigationInterface => {
  const { t } = useTranslation();

  const navigation: Array<{ label: string; items: NavigationItemInterface[] }> =
    [
      {
        label: t("NAVIGATION.overview"),
        items: [
          {
            label: t("NAVIGATION.prescription-upload"),
            route: "/upload",
          },
          {
            label: t("NAVIGATION.signature"),
            route: "/signature",
          },
        ],
      },
    ];

  return { navigation };
};
export default useNavigation;
