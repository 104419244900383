import React, { ReactElement } from "react";

const ChevronRightIcon = (): ReactElement => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.683058 0.433058C0.927136 0.188981 1.32286 0.188981 1.56694 0.433058L4.69194 3.55806C4.93602 3.80214 4.93602 4.19786 4.69194 4.44194L1.56694 7.56694C1.32286 7.81102 0.927136 7.81102 0.683058 7.56694C0.438981 7.32286 0.438981 6.92714 0.683058 6.68306L3.36612 4L0.683058 1.31694C0.438981 1.07286 0.438981 0.677136 0.683058 0.433058Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);

export default ChevronRightIcon;
