import styled from "styled-components";

const StyledField = styled.label`
  display: block;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 2rem;
  margin-bottom: 2rem;
`;

export default StyledField;
