import React, { ReactElement, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Overlay from "./Overlay";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfViewerProps {
  pdfData: any;
  handleFileUrl: (fileUrl: string) => void;
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PdfViewer = ({
  pdfData,
  handleFileUrl,
}: PdfViewerProps): ReactElement => {
  const [file, setFile] = useState<Blob | null>(null);

  useEffect(() => {
    if (pdfData === null) {
      return;
    }
    const fileBlob = new Blob([pdfData], { type: "application/pdf" });
    setFile(fileBlob);
    const fileURL = URL.createObjectURL(fileBlob);
    handleFileUrl(fileURL);
  }, [pdfData]);

  return (
    <>
      {file !== null && (
        <>
          <Overlay />
          <StyledDiv>
            <Document file={file} renderMode="canvas">
              <Page pageNumber={1} />
            </Document>
          </StyledDiv>
        </>
      )}
    </>
  );
};

export default PdfViewer;
