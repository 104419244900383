import React, { ReactElement, useEffect, useState } from "react";
import { Button, Form } from "../components";
import Right from "../components/Right";
import CheckIcon from "../icons/CheckIcon";
import { useTranslation } from "react-i18next";
import useAuthentication from "../api/useAuthentication";
import { useNavigate } from "react-router-dom";

const LoginForm = (): ReactElement => {
  const { t } = useTranslation();
  const { login, isLoggedIn } = useAuthentication();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate("/upload");
  }, [isLoggedIn]);

  return (
    <Form
      fields={[
        {
          id: "email",
          label: t("USER.email"),
          type: "email",
        },
        {
          id: "password",
          label: t("USER.password"),
          type: "password",
        },
      ]}
      initialValues={{
        email: "",
        password: "",
      }}
      handleSubmit={(loginData) => {
        setIsSubmitting(true);
        void login({
          email: loginData.email,
          password: loginData.password,
        })
          .then((data) => {
            if (data === null) {
              setIsSubmitting(false);
              return;
              // todo
            }
            setIsSubmitting(false);
          })
          .catch(() => {
            // todo errors
            setIsSubmitting(false);
          });
      }}
    >
      <Right>
        <Button
          type="submit"
          disabled={isSubmitting}
          variant={isSubmitting ? "success" : "default"}
          whileTap={{
            scale: 0.8,
          }}
        >
          {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
        </Button>
      </Right>
    </Form>
  );
};

export default LoginForm;
