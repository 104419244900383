import useApi from "./useApi";
import { UploadPrescriptionInterface } from "../interfaces/UploadPrescriptionInterface";

interface UsePostSignatureInterface {
  create: (file: File) => Promise<UploadPrescriptionInterface | null>;
}

const usePostSignature = (): UsePostSignatureInterface => {
  const { post } = useApi();

  const create = async (
    file: File
  ): Promise<UploadPrescriptionInterface | null> => {
    const formData = new FormData();
    formData.append("file", file);

    const { status, data } = await post("/signature/file", formData, {
      "Content-Type": "multipart/form-data",
    });

    if (status === 200) {
      return data;
    }

    return null;
  };

  return { create };
};

export default usePostSignature;
