import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface TextProps {
  children: ReactNode;
  variant?: "default" | "light" | "danger";
  color?: string;
}

const StyledSpan = styled.span<{ color?: string }>`
  color: ${({ theme, color }) => theme.colors[color ?? "black"]};
`;

const StyledLightText = styled.span`
  color: ${({ theme }) => theme.colors["black/40"]};
`;

const StyledDangerText = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 700;
`;

const Text = ({
  children,
  variant = "default",
  color,
}: TextProps): ReactElement => {
  switch (variant) {
    case "default":
      return <StyledSpan color={color}>{children}</StyledSpan>;
    case "light":
      return <StyledLightText>{children}</StyledLightText>;
    case "danger":
      return <StyledDangerText>{children}</StyledDangerText>;
  }
};

export default Text;
