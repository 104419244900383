import React, { ReactElement } from "react";
import { InputFieldProps } from "../../interfaces/InputFieldProps";
import StyledField from "../styles/StyledField";
import StyledInputField from "../styles/StyledInputField";
import StyledLabel from "../styles/StyledLabel";
import Tag from "../Tag";

const RelationField = ({
  label,
  name,
  handleChange,
  handleBlur,
  value,
  populate,
}: InputFieldProps): ReactElement => {
  if (!Array.isArray(value)) {
    throw new Error("Invalid form data");
  }

  const renderSelectOrInput = (): ReactElement => {
    if (populate === undefined || populate[name] === undefined) {
      return (
        <StyledInputField
          type="text"
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    }

    return (
      <select name={name} onChange={handleChange} onBlur={handleBlur}>
        {populate[name].map((pop) => (
          <option key={pop.id} value={pop.id}>
            {pop.label}
          </option>
        ))}
      </select>
    );
  };

  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      {value.map(({ id, label }) => (
        <Tag key={id}>{label} </Tag>
      ))}
      {renderSelectOrInput()}
    </StyledField>
  );
};

export default RelationField;
