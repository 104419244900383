import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Card, Headline, Space } from "../components";
import { useTranslation } from "react-i18next";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import Loading from "../components/Loading";
import styled from "styled-components";
import usePostSignature from "../api/usePostSignature";
import useGetSignature from "../api/useGetSignature";

const StyledSignatureImage = styled.img`
  width: 300px;
`;

const Signature = (): ReactElement => {
  const { t } = useTranslation();
  const { create } = usePostSignature();
  const { request } = useGetSignature();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [chosenFile, setChosenFile] = useState<File | null>(null);
  const [signatureFile, setSignatureFile] = useState<string | null>(null);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }

    void request().then((file) => {
      const imageUrl = window.URL.createObjectURL(file);
      setSignatureFile(imageUrl);
    });
  }, [status]);

  useEffect(() => {
    if (chosenFile === null) {
      return;
    }
    setStatus("loading");
    void create(chosenFile)
      .then(() => {
        setStatus("initial");
      })
      .catch(() => {
        setStatus("error");
      });
  }, [chosenFile]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.currentTarget.files;

    if (files === null) {
      return;
    }

    const file = files.item(0);
    setChosenFile(file);
  };

  return (
    <>
      <Headline pl="1rem">{t("SIGNATURE.file")}</Headline>
      {signatureFile !== null && (
        <>
          <Card>
            <StyledSignatureImage src={signatureFile} />
          </Card>
          <Space size={2} />
        </>
      )}
      <Card>
        <input type="file" onChange={handleFileChange} />
        {status === "error" && <>Fehler: Nur PNG-Dateien erlaubt.</>}
      </Card>
      <Space size={2} />
      {status === "loading" && (
        <Card color="purple">
          <Loading />
        </Card>
      )}
    </>
  );
};

export default Signature;
