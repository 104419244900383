import useApi from "./useApi";

interface UsePostFileInterface {
  request: (token: string, key: string) => Promise<any>;
}

const usePostRecipe = (): UsePostFileInterface => {
  const { get } = useApi();

  const request = async (token: string, key: string): Promise<any> => {
    const { status, data } = await get(
      `/${token}`,
      {
        "x-api-token": key,
      },
      true
    );

    if (status === 200) {
      return data;
    }

    return null;
  };

  return { request };
};

export default usePostRecipe;
