import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface ButtonProps {
  children: ReactNode;
  type?: "submit";
  disabled?: boolean;
  variant?: "default" | "success";
  block?: boolean;
  handleClick?: () => void;
}

const StyledButton = styled.button<{
  variant: "default" | "success";
  block: boolean;
}>`
  border: 0;
  border-radius: 1rem;
  background: ${({ theme, variant }) =>
    variant === "success" ? theme.colors.success : theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem 2rem;
  outline: none;
  cursor: pointer;
  ${({ block }) => (block ? "display: block; width: 100%;" : "")}

  &:hover {
    background: ${({ theme, variant }) =>
      variant === "success" ? theme.colors.success : theme.colors.darkgray};
  }

  svg {
    vertical-align: middle;
    margin-left: 1rem;
  }
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = "submit",
      disabled = false,
      variant = "default",
      block = false,
      handleClick,
    },
    ref
  ): ReactElement => {
    return (
      <StyledButton
        ref={ref}
        type={type}
        disabled={disabled}
        variant={variant}
        block={block}
        onClick={handleClick}
      >
        {children}
      </StyledButton>
    );
  }
);
Button.displayName = "Button";

export default motion(Button);
