import { Formik } from "formik";
import React, { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, InputField, Space, Text } from "../components";
import { useTranslation } from "react-i18next";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import { UploadPrescriptionInterface } from "../interfaces/UploadPrescriptionInterface";
import Loading from "../components/Loading";
import useGetRecipe from "../api/useGetRecipe";
import prescriptionImage from "../assets/prescription.png";
import StyledBlockImage from "../components/styles/StyledBlockImage";
import SingleColumnWrapper from "../components/styles/SingleColumnWrapper";
import StyledParagraph from "../components/styles/StyledParagraph";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import Center from "../components/Center";
import authorityImage from "../assets/dirkohercher.jpg";
import legitimation1 from "../assets/legitimation-1.jpeg";
import legitimation2 from "../assets/legitimation-2.jpeg";
import styled from "styled-components";

const StyledImg = styled.img`
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 600px) {
    max-width: 50%;
    margin-right: 2rem;
  }
`;

const StyledDiv = styled.div`
  @media only screen and (min-width: 600px) {
    display: flex;
  }
`;

const StyledParagraphWithMarginBottom = styled(StyledParagraph)`
  margin-bottom: 2rem;
`;

const StyledLegitimationDiv = styled.div`
  position: absolute;
  margin-top: -620px;
  text-align: center;
`;

const StyledLegitimationImg = styled.img`
  height: 300px;
`;

const RequestPrescription = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { request } = useGetRecipe();

  const [data, setData] = useState<{
    status: RequestStatusType;
    message: string;
  }>({
    status: "initial",
    message: "",
  });
  const [pdf, setPdf] = useState<BufferSource | null>(null);
  const [pdfFileUrl, setPdfFileUrl] = useState<string | null>(null);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [isShowLegitimation, setIsShowLegitimation] = useState<boolean>(false);

  const initialValues: UploadPrescriptionInterface = {
    token: id ?? "",
    key: "",
  };

  return (
    <SingleColumnWrapper>
      {pdf !== null && (
        <>
          <StyledParagraph>
            <Text color="danger">
              <strong>Wichtig:</strong>
            </Text>{" "}
            <Text>
              Bitte händigen Sie das Medikament nur aus, wenn Sie oben am Rezept
              ein vollständiges Wasserzeichen mit der Aufschrift &quot;REZEPT
              SAFE&quot; sehen.
            </Text>
          </StyledParagraph>
          <PdfViewer
            pdfData={pdf}
            handleFileUrl={(fileUrl) => {
              setPdfFileUrl(fileUrl);
            }}
          />
        </>
      )}
      {pdfFileUrl !== null && (
        <>
          <Space size={2} />
          <Center>
            <div>
              <div>
                <Center>
                  <strong>Apothekenhotline: 02204/4784050</strong>
                </Center>
                <Space size={2} />
              </div>
              <div>
                <Center>
                  {isShowLegitimation && (
                    <StyledLegitimationDiv>
                      <StyledLegitimationImg
                        src={legitimation1}
                        alt="Arztausweis Vorderseite"
                      />
                      <StyledLegitimationImg
                        src={legitimation2}
                        alt="Arztausweis Rückseite"
                      />
                    </StyledLegitimationDiv>
                  )}
                  <Button
                    handleClick={() => {
                      setIsShowLegitimation(!isShowLegitimation);
                    }}
                  >
                    {isShowLegitimation
                      ? t("PRESCRIPTION.doctor-legitimation-hide")
                      : t("PRESCRIPTION.doctor-legitimation-show")}
                  </Button>
                </Center>
              </div>
              <div>
                <Space size={2} />
                {!downloaded && (
                  <Button
                    handleClick={() => {
                      window.open(pdfFileUrl);
                      setDownloaded(true);
                    }}
                  >
                    {t("PRESCRIPTION.download")}
                  </Button>
                )}
                {downloaded && (
                  <StyledParagraph>
                    <Text>{t("PRESCRIPTION.close-window")}</Text>
                  </StyledParagraph>
                )}
              </div>
            </div>
          </Center>
        </>
      )}
      {pdf === null && (
        <>
          <Formik<UploadPrescriptionInterface>
            initialValues={initialValues}
            onSubmit={async ({ key, token }, { setSubmitting }) => {
              setSubmitting(false);
              if (key === "" || token === "") {
                setData({
                  status: "error",
                  message: t("ERRORS.invalid-input"),
                });
                return;
              }

              setData({
                status: "loading",
                message: "",
              });

              const pdfSource = await request(token, key);

              if (pdfSource === null) {
                setData({
                  status: "error",
                  message: t("ERRORS.not-found"),
                });
                return;
              }
              setPdf(pdfSource);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {id !== undefined && (
                    <>
                      <StyledBlockImage
                        src={prescriptionImage}
                        alt={`${t("PRESCRIPTION.image")}`}
                      />
                      <Space size={2} />
                    </>
                  )}
                  {id === undefined && (
                    <InputField
                      label={`${t("PRESCRIPTION.token")}:`}
                      type="text"
                      name="token"
                      handleChange={handleChange}
                      value={values.token}
                    />
                  )}
                  <InputField
                    label={`${t("PRESCRIPTION.key")}:`}
                    type="text"
                    name="key"
                    handleChange={handleChange}
                    value={values.key}
                  />
                  {data.status === "error" && (
                    <Text variant="danger">{data.message}</Text>
                  )}
                  <Card>
                    <StyledParagraph>
                      <Text>{t("PRESCRIPTION.disclaimer")}</Text>
                    </StyledParagraph>
                  </Card>
                  <Space size={2} />
                  <Button block disabled={data.status === "loading"}>
                    {t("BUTTONS.request")}
                    {data.status === "loading" && (
                      <Loading fill="#ffffff" size={16} />
                    )}
                  </Button>
                  <Space size={2} />
                  <StyledDiv>
                    <StyledImg
                      src={authorityImage}
                      alt="Bild von Dirko Hercher"
                    />
                    <div>
                      <StyledParagraphWithMarginBottom>
                        Sehr geehrte Frau Apothekerin,
                        <br />
                        Sehr geehrter Herr Apotheker,
                      </StyledParagraphWithMarginBottom>
                      <StyledParagraphWithMarginBottom>
                        im Rahmen einer Online-Sprechstunde wurde ein
                        Privatrezept erstellt.
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        Sie finden das Originalrezept unter der Ihnen genannten
                        URL.
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        Es kann nur mit der PIN (liegt der/dem Pat. vor)
                        einmalig geöffnet werden.
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        Bitte händigen Sie das Präparat oder einen alternativen
                        Hersteller aus.
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        Vielen Dank für Ihre freundliche Kooperation.
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        Mit freundlichen Grüßen
                      </StyledParagraphWithMarginBottom>

                      <StyledParagraphWithMarginBottom>
                        D. Hercher
                        <br />
                        FA für Urologie
                      </StyledParagraphWithMarginBottom>
                    </div>
                  </StyledDiv>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </SingleColumnWrapper>
  );
};

export default RequestPrescription;
