const theme = {
  sizes: {
    basic: "8px",
  },
  colors: {
    white: "#FFFFFF",
    black: "#1C1C1C",
    darkgray: "#494949",
    gray: "rgba(0,0,0,0.1)",
    light: "#F7F9FB",
    blue: "#E3F5FF",
    purple: "#E5ECF6",
    "black/40": "#00000066",
    "black/20": "rgba(0, 0, 0, 0.2)",
    "black/5": "#0000000D",
    success: "#28a745",
    danger: "#FF4747",
  },
  shadows: {
    default: "0 0 2rem",
    soft: "0 0 0.5rem",
  },
};

export default theme;
