interface GetPdfTextProps {
  firstLine: string;
  url: string;
  key: string;
}

const getPdfText = ({ firstLine, url, key }: GetPdfTextProps): string => {
  return `${firstLine}
                
Ihr Rezept wurde auf eine gesicherte URL übertragen. 

Unter dieser URL kann der/die Apotheker/in das Rezept einmalig einsehen:

${url}
              
Dazu wird die folgende PIN benötigt: ${key}

Bitte leiten Sie beides an die Apotheke weiter. 

Mit freundlichen Grüßen
D. Hercher`;
};

export default getPdfText;
